:root {

  --text-color-default: #191919;
  --text-color-secondary: #474747;
  --text-color-secondary-light: #788d98;
  --text-color-light-secondary: #a3a3a3;
  --text-color-light-invert: #f2f2f2;
  --bg-color-main: #f6f5f0;
  --bg-color-secondary: #bababa;
  --bg-color-light-secondary: #f2f2f2;
  --bg-color-hover: #e7eef4;
  --bg-color-janus: #191919;
  --bg-color-chat-message: #191919;
  --bg-color-gray-theme-selected: #a3a3a3;
  --bg-color-gray-theme-canban-button: #d1d1d1;
  --bg-row-color: #f5f7fa;
  --border-color-base: #bababa;
  --border-color-base-light: #e5e5e5;
  --icon-milestone-neutral: #d9d9d9;
  --icon-light: #f2f2f2;
  --avatar-main: #81d4fa;
  --link-active: #6175e1;
  --link-active-2: #2d81f7;

  --btn-default: #08bf32;
  --btn-default-hover: #39cb5b;;

  --primary-color: #7fad39;
  --seconday-color: #120851;
  --white-color: #ffffff;
  --heading-color: #111111;
  --para-color: #6f6f6f;
  --black-color: #000000;
  --heading-color-2: #252525;
  --normal-color: #1c1c1c;
  --background: #f5f5f5;
  --background-2: #f2f2f2;
  --border: #ebebeb;
  --border-1: #e1e1e1;
  
  --fm-unna: 'Unna', serif;
  --fm-nunito: 'Nunito Sans', sans-serif;

}