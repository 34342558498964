.comments-block {
  position: relative;
  max-width: 100%;
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: 8px;
  margin-right: 8px;
  // width: calc(100% - 8px);
  background: #fff;
  border-radius: 2px;
  padding: 18px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.2);
}

.comments {
  padding-top: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;

  &__inputblock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;

  }

  &__input {
    padding: 5px;
    border-bottom: 2px solid lightgray;
    width: 100%;
    // box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 0 0 0 rgba(0,0,0,.2);
    // border-bottom: 2px solid transparent;

    &:focus {
      border-bottom: 2px solid #00b028;
      box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
     
    }
  }

  &__comment {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    & > .date {
      align-self: center;
      color: var(--text-color-secondary-light);
      font-size: 12px;
    }

    &__info {
      display: flex;
      justify-content: space-between;

      & > .item {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: flex-start;

        & > .avatar {
          display: flex;
          gap: 5px;
          align-items: center;

          & span:nth-child(1) {
            width: 36px;
            height: 36px;
            border-radius: 100%;
            background-color: rgb(95, 196, 95);
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #fff;
          }

        }
        
        & > .main-container {
          margin-top: 3px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          & > .name {
            color: var(--text-color-secondary-light);
            font-size: 14px;
            
          }
          
          & > .comment {
              // padding-top: 10px;
              color: var(--text-color-default);
              white-space: pre-wrap;
          }

          & > .deal-item {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--link-active-2);

            & > span {
              margin-top: 3px;
            }
          }

        }

      }

      & > .time {
        color: var(--text-color-secondary-light);
        font-size: 12px;
      }

      // & > .comment {
      //   padding-top: 10px;

      //   & > .deal {
      //     padding: 10px;
      //     display: flex;
      //     gap: 5px;
      //     align-items: center;
      //   }
      // }
    }

  }

}