.pagenotfound {
  flex: 1 0 auto;
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 40px;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  
  &__text {
    font-size: 50px;
  }

  &__button {
    background-color: transparent;
    color: #3e425e;
    text-transform: uppercase;
    border-radius: 2px;
    padding: 10px 16px;
    font-size: 12px;
    transition: all 0.3s;

    &:hover {
      background-color: #f0f0f3;
    }
  }
}