input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.add-order {
  // background-image: url('../../assets/main-bg.png') ;
  max-width: 100%;
  flex: 1 1 auto;
  padding: 15px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: flex;
  background-color: white;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.2);

  &__container {
    max-width: 100%;
    // flex-basis: 80%;
    flex-grow: 1;
    flex-shrink: 0;

  }

  &__header {
    display: flex;
    justify-content: space-between;
    // padding: 10px;
    // padding: 15px 15px 0 15px;
    width: 100%;

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      & > .title {
        font-size: 18px;

      }

      & > .icons {
        display: flex;
        gap: 5px;
        // align-items: flex-end;
        & > .add-btn {
          background-color: var(--btn-default);
          color: #fff;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px 10px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: var(--btn-default-hover);
          }
        }
    
        & > .cansel-btn {
          background-color: transparent;
          color: #3e425e;
          border: 1px solid #cacaca;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px 10px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: #f0f0f3;
          }
        }
      }
    }

  }

  &__main {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    // padding-left: 5px;
    // border-radius: 2px;

    &__row {
      // background-color: white;
      display: flex;
      align-items: center;
      // gap: 20px;
      color: var(--text-color-secondary-light);
      padding-left: 10px;
      // margin-bottom: 5px;
      width: 100%;
      justify-content: space-between;

      & > input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      & > input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      
      &.first_row {
        border-bottom: 1px solid var(--border-1);

        & > .cell {
          &:hover {
            // background-color: var(--bg-color-hover);
            cursor: default;
          }

          &.narrow{
            max-width: 70px;
            padding: 0;
            text-align: center;
          }

          &.tight{
            max-width: 100px;
            padding: 0;
            text-align: center;
          }

          &.medium {
            max-width: 150px;
            text-align: right;
          }
        }

      }

      & > .cell {
        flex: 1;
        max-width: 350px;
        padding: 5px 20px 5px 8px;
        // line-height: 16px;

        &.data {
          color: var(--text-color-default);
          cursor: default;

          &.narrowest {
            max-width: 14px;
            padding: 0;
            cursor: move;
          }

          &.narrow {
            max-width: 70px;
            padding: 5px 0;
            text-align: center;

            &:-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            &:-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          &.tight{
            max-width: 100px;
            padding: 5px 0;
            text-align: center;
          }

          &.medium {
            max-width: 150px;
            text-align: right;
          }

          &.trash {
            // max-width: 15px;
            transition: all 0.2s;

            &:hover {
              color: red;
            }
            &:active {
              transform: scale(0.8);
            }

          }
        }

        &.total {
          font-weight: 700;
          color: #01791d;
        }
      }

      & > input {
        border-bottom: 2px dotted lightgray;
        
  
        &:focus {
          border-bottom: 2px solid #00b028;
          box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
        
        }
      }

    }
  }

  &__search {
    position: relative;
    padding: 20px;
    display: flex;
    gap: 5px;

    & > input {
      padding: 5px;
      border-bottom: 2px solid lightgray;
      width: 100%;
      // box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 0 0 0 rgba(0,0,0,.2);
      // border-bottom: 2px solid transparent;
  
      &:focus {
        border-bottom: 2px solid #00b028;
        box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
       
      }
    }

    &__result {
      max-width: 600px;
      width: 100%;
      max-height: 400px;
      overflow-y: scroll;
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 50px;
      left: 20px;
      // padding: 10px;
      background-color: #fff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5), 0 2px 2px 0 rgba(0, 0, 0, 0.3);
      z-index: 10;

      & > span {
        padding: 15px;
        cursor: pointer;
      }

      & > span:hover {
        background-color: var(--bg-color-secondary);
      }

      & > .add-product-block {
        padding: 10px;
        display: flex;
        gap: 10px;
        align-items: center;

        & > button {
          background-color: transparent;
          color: #3e425e;
          border: 1px solid #cacaca;
          // text-transform: uppercase;
          border-radius: 2px;
          padding: 5px 10px;
          font-size: 12px;
          transition: all 0.3s;
          width: 115px;
    
          &:hover {
            background-color: #f0f0f3;
          }
        }
      }
    }

    & > button {
      background-color: transparent;
      color: #3e425e;
      border: 1px solid #cacaca;
      // text-transform: uppercase;
      border-radius: 2px;
      padding: 5px 10px;
      font-size: 12px;
      transition: all 0.3s;
      width: 110px;

      &:hover {
        background-color: #f0f0f3;
      }
    }
  }

  &__bills {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px;

    & > a {
      color: var(--link-active-2);
      font-size: 16px;
      font-weight: 500;
    }
  }

}