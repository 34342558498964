.company-card {
  background-image: url('../../../assets/main-bg.jpg') ;
  width: 100%;
  flex: 1 1 auto;
  padding: 12px 10px 38px 24px;

  &__header {

    &__title {
      display: flex;
      align-items: center;
      gap: 16px;

      & > .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background-color: var(--avatar-main);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: var(--icon-light);
        flex-shrink: 0;
        // margin-right: 6px;
      }

      & > .title {
        font-size: 32px;
        font-weight: 300;
        display: flex;
        gap: 5px;
        line-height: normal;
        width: fit-content;

        & > input {
          padding: 0px 5px 3px 5px;
          font-size: 24px;
          border-bottom: 2px solid lightgray;
          
    
          &:focus {
            border-bottom: 2px solid #00b028;
            box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
          
          }
        }

        & > .add-btn {
          background-color: var(--btn-default);
          color: white;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: var(--btn-default-hover);
          }
        }
    
        & > .cansel-btn {
          background-color: transparent;
          color: #3e425e;
          border: 1px solid #cacaca;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: #f0f0f3;
          }
        }
      }


      
    }

    &__links {
      display: flex;
      padding: 20px 0px;
      gap: 30px;
      color: var(--text-color-default);

      & > .active {
        color: var(--link-active);
        border-bottom: 2px solid var(--link-active);
        padding-bottom: 5px;
      }
    }
  }

  &__wrapper {
    // height: 68vh;
    // background: rgba(255,255,255, 0.4);
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    width: 100%;

    & > .left {
      max-width: 100%;
      flex-basis: 60%;
      flex-grow: 1;
      flex-shrink: 0;
    }

    & > .right {
      flex-basis: 34%;
    }
  }

}