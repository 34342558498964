.deal-for-day {
  width: 100%;

  &__container {
    width: 100%;
    display: flex;
    gap: 5px;
  }

  &__calendar {
    padding-top: 20px;

  }

  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &__title {
      font-size: 32px;
      padding: 15px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      & > a {
        background-color: #fff;
        // color: #3e425e;
        color: #65abec;
        border: 1px solid #65abec;
        // text-transform: uppercase;
        border-radius: 2px;
        padding: 5px 10px;
        font-size: 18px;
        font-weight: 500;
        transition: all 0.3s;
        width: 110px;
  
        &:hover {
          background-color: #f0f0f3;
        }
      }

    }

    &__block {
      background-color: #fff;
      margin-top: 10px;
      box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.2);
      padding: 15px;

      &__title {
        font-size: 19px;
        font-weight: 500;
        padding-bottom: 10px;
      }
    
      &__item {
        display: flex;
        gap: 5px;
        padding: 10px;
        border-bottom: 1px solid var(--border-1);
      }

      &__time {
        max-width: 40px;
        width: 100%;
      }

      &__info {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 5px;

        & > a {
          width: fit-content;
          padding: 2px 5px;
          border-radius: 5px;
          background: #fff7e9;
          color: var(--text-color-default);
          border: 1px solid rgba(246,166,35,.15);
          cursor: pointer;
        }
      }

      // &__row {
      //   display: flex;
      //   align-items: center;
      //   // gap: 20px;
      //   color: var(--text-color-secondary-light);
      //   padding-left: 10px;
      //   // margin-bottom: 5px;
      //   width: 100%;
      //   justify-content: space-between;

      //   &.first_row {
      //     // border-bottom: 1px solid var(--border-1);
      //     // border: 1px solid var(--border-1);

      //     & > .cell {
      //       border: 1px solid var(--border-1);

      //       &.active {
      //         background-color: var(--border-1);
      //       }

      //       &.first_cell {
      //         max-width: 50px;
      //       }

      //     }

      //     & > span:first-child {
      //       border: none;
      //     }
      //   }

      //   & > .cell {
      //     flex: 1;
      //     // max-width: 350px;
      //     padding: 5px 20px 5px 8px;

      //     &.first_cell {
      //       max-width: 50px;
      //     }

      //     &.items {
      //       // flex: none;
      //       // display: flex;
      //       white-space: nowrap;
      //       text-overflow: ellipsis;
      //       overflow: hidden;
            
      //     }

      //   }
      // }

      // & > .first_cell {
      //   max-width: 50px;
      // }
    }
  }
}