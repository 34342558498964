.contactsblock {
  position: relative;
  max-width: 100%;
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: 8px;
  margin-right: 8px;
  // width: calc(100% - 8px);
  background: rgb(255, 255, 255);
  border-radius: 2px;
  padding: 18px;
  // margin: 20px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.2);
  flex-basis: 25%;

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 6px;

    & > .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > span {
        font-size: 16px;
      }
    }

    & > .data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 15px;
      transition: 0.3s opacity ease;
      cursor: default;
      
      & > .text {
        width: 100%;
        padding-top: 10px;
        display: flex;
        gap: 15px;

        & > .span-number {
          color: var(--link-active-2);
          flex: 0 130px;

          &.active {
            font-weight: 700;
          }
        }

        & > .span-email {
          color: var(--link-active-2);
          flex: 0 170px;

          &.active {
            font-weight: 700;
          }
        }

        & span:nth-child(2) {
          flex: 1 1;
        }

        & > .span-address {
          width: 100%;
        }
      }

      &:hover .icons {
        opacity: 1;
      }

      & > .icons {
        display: flex;
        gap: 10px;
        opacity: 0;
        transition: 0.3s opacity ease;
      }

      &.last {
        padding-bottom: 10px;
      }
    }

    &__inputs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      gap: 10px;

      &.update {
        padding: 0;
      }

      & > input {
        width: 100%;
        padding: 5px 0px;
        border-bottom: 2px solid lightgray;
  
        &:focus {
          border-bottom: 2px solid #00b028;
          box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
        
        }
      }

      & > textarea {
        width: 100%;
        padding: 5px 5px;
        border-bottom: 2px solid lightgray;
        resize: none;
  
        &:focus {
          border-bottom: 2px solid #00b028;
          box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
        
        }
      }

      & input:first-child {
        max-width: 120px;
        width: 100%;

      }

      & > .add-btn {
        background-color: var(--btn-default);
        color: white;
        text-transform: uppercase;
        border-radius: 2px;
        padding: 5px;
        font-size: 12px;
        transition: all 0.3s;
  
        &:hover {
          background-color: var(--btn-default-hover);
        }
      }
  
      & > .cansel-btn {
        background-color: transparent;
        color: #3e425e;
        border: 1px solid #cacaca;
        text-transform: uppercase;
        border-radius: 2px;
        padding: 5px;
        font-size: 12px;
        transition: all 0.3s;
  
        &:hover {
          background-color: #f0f0f3;
        }
      }

      & > .buttons {
        display: flex;
        gap: 5px;
        height: 32px;

        & > .add-btn {
          background-color: var(--btn-default);
          color: #c02525;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px 10px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: var(--btn-default-hover);
          }
        }
    
        & > .cansel-btn {
          background-color: transparent;
          color: #3e425e;
          border: 1px solid #cacaca;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px 10px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: #f0f0f3;
          }
        }
      }

      & > .address {
        max-width: 400px !important; 
      }
    }
  }
}
 
