.form-input {
  position: relative;
  max-width: 526px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__label {
    position: absolute;
    align-self: flex-start;
    // font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    // line-height: 30px;
    color: #A6A6A6;
    // z-index: 2;
    top: 40px;
    left: 4px;
    background-color: #f8f8f6;
    transition: all 0.3s ease;
  }

  &__text {
    max-width: 526px;
    width: 100%;
    padding: 5px;
    margin-top: 30px;
    border-bottom: 1px solid #B8B8B8;
    // font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    z-index: 2;
    background-color: transparent;

    &:-webkit-autofill {
        // -webkit-text-fill-color: #31b0dd;
        -webkit-box-shadow: 0 0 0px 40rem #f8f8f6 inset;
    }

    &:-webkit-autofill:hover {
      // -webkit-text-fill-color: #31b0dd;
      -webkit-box-shadow: 0 0 0px 40rem #f8f8f6 inset;
    }

    &:-webkit-autofill:focus {
      // -webkit-text-fill-color: #31b0dd;
      -webkit-box-shadow: 0 0 0px 40rem #f8f8f6 inset;
    }

    // &:-internal-autofill-selected {
    //   background-color: rgb(255, 255, 255) !important;
    //   background-image: none !important;
    // }

    // &:-internal-light-dark {
    //   background-color: rgb(255, 255, 255) !important;
    //   background-image: none !important;
    // }

    &::selection {
      background-color: transparent;
    }

    &::-moz-selection {
      background-color: transparent;
    }

    &:invalid[datatype='true'] ~ .form-input__error {
      opacity: 1;
    }

    &:invalid[datatype='true'] {
      border: 1px solid red;
      border-radius: 5px;
    }

    &:focus ~ .form-input__label {
      top: 10px;
      font-size: 12px;
      z-index: 10;
    }

    &:not(:placeholder-shown) ~ .form-input__label {
      top: 10px;
      font-size: 12px;
      z-index: 10;
    }
  }

  &__error {
    width: 100%;
    height: 20px;
    padding: 5px;
    text-align: start;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #e33e3e;
    opacity: 0;
  }
}
// .form-input__text:focus ~ label {
//   top: -20px
// }