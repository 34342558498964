.add-product {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  animation-name: appear;
  animation-duration: 300ms;
  overflow: hidden;
  
  &__dialog {
    width: 100%;
    max-width: 720px;
    background: white;
    position: relative;
    margin: 0 20px;
    max-height: calc(100vh - 40px);
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
    padding: 25px;
    gap: 20px;
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &__dealtitle {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  &__header {
    display: flex;
    align-items: center;
    // padding: 1rem;
    border-bottom: 1px solid #dbdbdb;
    justify-content: space-between;
  }

  &__body {
    // overflow: auto;
    // padding: 1rem;
    width: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    // padding-left: 5px;
    // border-radius: 2px;

    &__row {
      display: flex;
      align-items: center;
      gap: 20px;
      color: var(--text-color-secondary-light);
      padding-left: 10px;
      margin-bottom: 15px;
      
      &.first_row {
        border-bottom: 1px solid var(--border-1);

      }

      &_cell {
        flex: 1;
        max-width: 550px;
        padding: 8px 20px 8px 8px;
        line-height: 16px;

        &.narrow{
          max-width: 60px;
          padding: 5px 0;
          text-align: center;
        }

        &.data {
          color: var(--text-color-default);
          cursor: default;

          &.narrowest{
            max-width: 14px;
            padding: 0;
          }

          &.narrow{
            max-width: 60px;
            padding: 5px 0;
            text-align: center;
          }

          &.tight{
            max-width: 100px;
            padding: 5px 0;
            text-align: center;
          }
        }

        &.total {
          font-weight: 700;
          color: #01791d;
        }
      }

      & > input {
        width: 100%;
        border-bottom: 2px solid lightgray;
        
  
        &:focus {
          border-bottom: 2px solid #00b028;
          box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
        
        }
      }

    }
  }

 

  &__footer{
    margin-top: 10px;
    padding: 1rem;
    border-top: 1px solid #dbdbdb;
    justify-content: flex-start;
    display: flex;
    gap: 20px;

    & .disabled {
      cursor: default !important; 
      opacity: 0.3 !important;
      // background-color: red !important;

      &:hover {
        background-color: var(--btn-default) !important;
      }
    }

    & button:nth-child(1) {
      background-color: var(--btn-default);
      color: white;
      text-transform: uppercase;
      border-radius: 2px;
      padding: 10px 16px;
      font-size: 12px;
      transition: all 0.3s;

      &:hover {
        background-color: var(--btn-default-hover);
      }
    }

    & button:nth-child(2) {
      background-color: transparent;
      color: #3e425e;
      border: 1px solid #cacaca;
      text-transform: uppercase;
      border-radius: 2px;
      padding: 10px 16px;
      font-size: 12px;
      transition: all 0.3s;

      &:hover {
        background-color: #f0f0f3;
      }
    }
  }

}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}