.dealsmain {
  background-image: url('../../assets/main-bg.jpg') ;
  width: 100%;
  flex: 1 1 auto;
  padding: 12px 25px 38px 24px;
  display: flex;
  flex-direction: column;

  &__calendar__deals {
    display: flex;
    gap: 5px;
    align-items: center;

    & > span:first-child {
      color: var(--link-active-2);
      font-weight: 500;
    }

    & > span:nth-child(2) {
      // display: flex;
      // align-items: center;
      // text-align: center;
      // justify-content: center;
      // width: 20px;
      // height: 20px;
      // border-radius: 100%;
      // background-color: green;
      // color: #fff;
      font-size: 14px;
      font-weight: 600;
    }

  }

}

.ant-picker-cell-inner {

  &.ant-picker-calendar-date {
    // background-color: red;
    // border: 1px solid black !important;
    box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 4px 4px 0 rgba(0,0,0,.2);
  }
}

