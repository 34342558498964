.employees-tab{
	// background-image: url('../../assets/main-bg.jpg') ;
	// background-color: red;
	width: 100%;
	// flex: 1 1 auto;
	padding: 12px 25px 38px 24px;
	display: flex;

	&__nav {
		padding: 0 10px;
    background-color: white;
    max-width: 100%;
    flex-basis: 30%;
    flex-grow: 1;
    flex-shrink: 0;
	}

	&__main {
		max-width: 100%;
    flex-basis: 65%;
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: 10px;
	}
}