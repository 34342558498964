.deals-block {
  max-width: 100%;
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: 8px;
  margin-right: 8px;
  // width: calc(100% - 8px);
  background: #fff;
  border-radius: 2px;
  padding: 18px;
  // margin: 20px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.2);
  flex-basis: 25%;

  &__newdeal {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-1);
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      & > .text {
        display: flex;
        align-items: center;
        gap: 6px;
  
        & > span {
          font-size: 18px;
        }
      }
  
      & > .icons {
        position: relative;
        display: flex;
        gap: 15px;

      }

    }
  
    &__add {
      display: flex;
      gap: 10px;
      align-items: center;
  
      & > span {
        color: var(--text-color-light-secondary);
      }
    }
  
  }
 
  &__deals {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;

    &__empty {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: #aebbcb;
    }
  
    &__item {
      display: flex;
      flex-direction: column;
      gap: 15px;
  
      &__title {
        color: var(--text-color-light-secondary);
        font-size: 12px;
  
      }
  
      &__info {
        display: flex;
        justify-content: space-between;
        
  
        & > .text {
          display: flex;
          gap: 5px;
  
          & > .item {
            display: flex;
            flex-direction: column;
            // gap: 5px;
  
            & span:first-child {
              color: red;
            }
  
            & span:nth-child(2) {
              font-size: 12px;
            }
          }
        }
  
        & > .icons {
          display: flex;
          gap: 10px;
        }
  
      }
  
    }
  
  }

}

.calendar-block {
  // padding: 10px;
  min-width: 450px;
  width: fit-content;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5), 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  right: 45px;
  top: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 10;
  // gap: 15px;
  color: black;

  &__title {
    font-size: 24px;
    font-weight: 500;
    align-self: center;
    padding: 10px;
  }

  &__block {
    display: flex;
    gap: 10px;

    &_left {

    }

    &_right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      & > .type {
        font-size: 20px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      & > .confirm {
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-self: center;
    
        & button:nth-child(1) {
          background-color: var(--btn-default);
          color: white;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 10px 16px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: var(--btn-default-hover);
          }
        }
    
        & button:nth-child(2) {
          background-color: transparent;
          color: #3e425e;
          border: 1px solid #cacaca;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 8px 16px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: #f0f0f3;
          }
        }
    
      }
    }
  }

  &.infoblock {
    left: 10px;
    // right: 45px;
    top: 5px;
  }

  & > .type {
    font-size: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & > .time {
    position: absolute;
    right: 16px;
    width: 90px;
    top: 42px;

  }

  
}

