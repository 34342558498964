.info-block {
  max-width: 100%;
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: 8px;
  margin-right: 8px;
  // width: calc(100% - 8px);
  background: #fff;
  border-radius: 2px;
  padding: 18px;
  // margin: 20px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.2);

  &__title {
    display: flex;
    gap: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-1);
    align-items: center;

    &__user {
      position: relative;
      display: flex;
      gap: 10px;
      align-self: flex-start;

      &__list {
        position: absolute;
        top: 40px;
        left: 0px;
        background-color: #fff;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5), 0 2px 2px 0 rgba(0, 0, 0, 0.3);
        // padding: 15px;
        width: max-content;
        display: flex;
        flex-direction: column;
        // padding-top: 20px;
        z-index: 10;
        // gap: 10px;

        &__no-users {
          display: flex;
          gap: 10px;
          // padding: 15px;

          & > span {
            cursor: pointer;
            color: #a3a3a3;
            border-bottom: 1px solid #a3a3a3;
            padding-bottom: 4px;
          }
        }

        & > .close {
          position: absolute;
          cursor: pointer;
          top: 3px;
          right: 3px;
        }

        & > ul {
          display: flex;
          flex-direction: column;

          & > li {
            display: flex;
            gap: 5px;
            align-items: center;
            padding: 10px 15px;
            // cursor: pointer;
            justify-content: space-between;

            & > .user-block {
              display: flex;
              gap: 5px;
              align-items: center;

              & > .avatar {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: rgb(95, 196, 95);
                color: #fff;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
              }
    
              & > .name {
                display: flex;
                flex-direction: column;
                cursor: default;
                // gap: 5px;
                
                & span:nth-child(2) {
                  color: var(--text-color-secondary-light);
                  font-size: 12px;
                }
              }
            }

            & > .trash {
              opacity: 0;
              transition: 0.5s all ease;
            }
  
            &:hover .trash {
              opacity: 1;
              // background-color: #f8f8f6;
              // color: red;
            }
  
            & > .avatar {
              width: 30px;
              height: 30px;
              border-radius: 100%;
              background-color: rgb(95, 196, 95);
              color: #fff;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
            }
  
            & > .name {
              display: flex;
              flex-direction: column;
              cursor: default;
              // gap: 5px;
              
              & span:nth-child(2) {
                color: var(--text-color-secondary-light);
                font-size: 12px;
              }
            }
          }
        }

        &__add {
          position: relative;
          display: flex;
          gap: 10px;
          padding: 15px;
          align-items: flex-end;

          & > span {
            cursor: pointer;
            color: #a3a3a3;
            border-bottom: 1px solid #a3a3a3;
            padding-bottom: 4px;
            
          }

          &__users {
            position: absolute;
            top: 40px;
            left: 45px;
            background-color: #fff;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5), 0 2px 2px 0 rgba(0, 0, 0, 0.3);
            // padding: 15px;
            width: max-content;
            display: flex;
            flex-direction: column;
            z-index: 10;

            & > li {
              display: flex;
              gap: 5px;
              align-items: center;
              padding: 10px 15px;
              cursor: pointer;
    
              &:hover {
                background-color: #f8f8f6;
              }
    
              & > .avatar {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: rgb(95, 196, 95);
                color: #fff;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
              }
    
              & > .name {
                display: flex;
                flex-direction: column;
                // gap: 5px;
                
                & span:nth-child(2) {
                  color: var(--text-color-secondary-light);
                  font-size: 12px;
                }
              }
            }
          }

        }

      }

      &__item {
        display: flex;
        gap: 10px;

        & > .avatar {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          background-color: rgb(95, 196, 95);
          color: #fff;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
  
        & > .name {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          // gap: 5px;
          
          & span:nth-child(2) {
            color: var(--text-color-secondary-light);
            font-size: 12px;
          }
        }

        & > span {
           cursor: pointer;
        }


      }


    }

    &__deal {
      display: flex;
      gap: 10px;

      & > .item {
        display: flex;
        flex-direction: column;
        gap: 5px;

        & span:nth-child(1) {
          color: red;
        }

        & span:nth-child(2) {
          color: var(--text-color-secondary-light);
          font-size: 12px;
        }
      }

    }

    &__nitification {
      color: var(--link-active-2);
      position: relative;
    }

  }

  &__description {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 20px;

    & > span {
      color: var(--text-color-default);
      white-space: pre-wrap;
    }

    &__inputs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      gap: 10px;

      & > textarea {
        width: 100%;
        padding: 5px 5px;
        border-bottom: 2px solid lightgray;
        // resize: none;
  
        &:focus {
          border-bottom: 2px solid #00b028;
          box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
        
        }
      }

      & > .buttons {
        display: flex;
        gap: 5px;
        height: 32px;

        & > .add-btn {
          background-color: var(--btn-default);
          color: white;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: var(--btn-default-hover);
          }
        }
    
        & > .cansel-btn {
          background-color: transparent;
          color: #3e425e;
          border: 1px solid #cacaca;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px;
          font-size: 12px;
          transition: all 0.3s;
    
          &:hover {
            background-color: #f0f0f3;
          }
        }
      }
    }
  }

}