.footer {
  width: 100%;
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-color: #e7e5dd;
  display: flex;
  z-index: 10;

  &__search {
    // position: relative;
    padding: 5px;
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;

    &__input {
      width: 100%;
      position: relative;
      max-width: 1200px;
      width: 100%;
      color: #65687e;

      & > input {
        color: #65687e;
        background-color: #f8f8f6;
        padding: 5px 40px;
        border-bottom: 2px solid lightgray;
        border-radius: 4px;
        // max-width: 1200px;
        width: 100%;
        font-size: 14px;
        // box-shadow: 0 0 2px 0 rgba(0,0,0,.1), 0 0 0 0 rgba(0,0,0,.2);
        // border-bottom: 2px solid transparent;
    
        &:focus {
          border-bottom: 2px solid #00b028;
          box-shadow: 0 0 2px 0 rgba(0,176,40,.1), 0 0 0 0 rgba(0,176,40,.2);
         
        }
      }
    }

    &__icon {
      position: absolute;
      top: 3px;
      left: 8px;
    }


    &__result {
      max-width: 1200px;
      width: 100%;
      max-height: 500px;
      overflow-y: scroll;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // top: 50px;
      left: 0px;
      bottom: 34px;
      // padding: 10px;
      background-color: #fff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5), 0 2px 2px 0 rgba(0, 0, 0, 0.3);

      & > a {
        color: #65687e;
        padding: 15px;
        cursor: pointer;
        width: 100%;
        text-align: start;
      }

      & > a:hover {
        background-color: #f8f8f6;
      }

      &__item {
        display: flex;
        gap: 10px;
        color: var(--text-color-default);

        &__icon {
          color: #65687e;
        }

        &__info {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        &__block {
          display: flex;
          gap: 5px;
          font-size: 12px;

          & > .title {
            font-weight: 600;
            font-size: 14px;
          }

          & > .title-name {
            font-size: 14px;
          }

          & > .user {
            color: #2d81f7;
          }

        }

      }

    }

  }

  &__wrapper {
    display: flex;
    // background: url(../../assets/img/footer.png) center bottom no-repeat;
    // background-size: cover;
    width: 100%;
    padding: 32px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 0 0 auto;
    color: #fff;

      // @media (max-width: 459px) {
      //   font-size: 14px;
      //   padding: 30px 5%;
      // }
    @media (max-width: 575px) { 
      flex-direction: column;
      gap: 10px;
      padding: 20px;
    }

  }

  &__links {
    display: flex;
    gap: 5px;
    color: #fff;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      color: var(--secondary)
    }

    &:hover {
      scale: 1.05;
    }

    &__text {
      font-family: "Open Sans", sans-serif;
      font-size: 25px;
      // color: #fff;
      font-weight: 600;
      line-height: 1.5;

      @media (max-width: 575px) { 
        font-size: 20px;
      }
    }

  }

  &__copywrite {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    background-color: #263A4F;
    padding: 10px;
    color: #fff;

    @media (max-width: 575px) { 
      flex-direction: column;
      gap: 12px;
    }
  }
  
  &__item {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: #f93;
    // color: #fff;
    
    @media (max-width: 850px) {
      width: 100%;
    }
  }

  &__text {
    color: #fff;
    // letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;
  }
}
