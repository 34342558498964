.header {
  width: 100%;
  max-width: 1950px;
  padding: 0 5%;
  border-bottom: 4px solid rgb(97, 117, 225);

  &__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {

    & .container {
      text-align: center;
      font-size: 24px;
      font-weight: 600;

      & span:nth-child(1) {
        color: #34AD54;
      }

      & span:nth-child(2) {
        color: #f93
      }

      & span:nth-child(3) {
        color: #34AD54;
      }
    }

  }

  &__nav {
    display: flex;
    // width: 100%;
    justify-content: center;
    gap: 15px;
    align-items: center;

    &__item {
      color: var(--text-color-secondary);
      font-size: 12px;
      padding: 15px;

      &.active {
        background-color: rgba(97, 117, 225, 0.2);
        margin: 10px;
        padding: 8px;
       }
    }

    // & > ul {
    //   display: flex;
    //   width: 100%;
    //   justify-content: space-between;
    //   align-items: center;

    //   & > li {
    //     color: var(--text-color-secondary);
    //     font-size: 12px;
    //     padding: 15px;

    //     &.active {
    //      background-color: rgba(97, 117, 225, 0.2);
    //      margin: 10px;
    //      padding: 8px;
    //     }
    //   }
    // }
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 10px;

    & span:nth-child(1) {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: lightgreen;
      color: #fff;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    & span:nth-child(2) {
      // color: var(--text-color-secondary-light);
      // font-size: 12px;
      color: var(--text-color-default);
      cursor: pointer;
    }

    & > button {
      background-color: transparent;
      color: #3e425e;
      border: 1px solid #cacaca;
      text-transform: uppercase;
      border-radius: 2px;
      padding: 5px 7px;
      font-size: 10px;
    }

    // & > .logo {
    //   width: 20px;
    //   height: 20px;
    //   border: 2px solid black;
    //   border-radius: 50%;
    // }

    // & > .name {
      
    // }
  }
}