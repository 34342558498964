.registration {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
  // font-family: 'Poppins', sans-serif;
  
  &__block {
    position: relative;
    // max-width: 450px;
    width: 100%;
    // min-height: 780px;
    // height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 3px 11px 5px rgb(0 0 0 / 50%);
    overflow: hidden;
  }

  &__container {
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 640px;
    width: 100%;
    height: 100%;
    // background: #e8faf8;
    // border-radius: 50px;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-around;
    // gap: 10px;
    text-align: center;
    box-shadow: 2px 3px 11px 5px rgb(0 0 0 / 50%);
    z-index: 5;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  &__title {
    // font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 5px;

    &__button {
      // margin-top: 40px;
      &_send {
        // width: 526px;
        // height: 74px;
        // background: #B0D8DA;
        border-radius: 5px;
        // font-family: 'Poppins', sans-serif;
        font-style: normal;
        // font-weight: bold;
        // font-size: 24px;
        // line-height: 45px;
        color: #FFFFFF;
        
        &.active {
          background: #4d9a0e;
          cursor: pointer;
        }
      }
    }
  }

  &__login-link {
    // margin: 40px 0 20px 0;
    // font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    // font-size: 18px;
    // line-height: 39px;
    color: #000000;

    &__link {
      font-size: 20px;
    }
  }
}
