@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// @import './variable';
html,
body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  // font-smoothing: antialiased;
  padding: 0 calc(18px - (100vw - 100%)) 0 0;
  // margin-right: calc(-1 * (100vw - 100%));
  // overflow-x:hidden;
}

body {
  width: 100%;
  display: flex;
  justify-content: center;
  // line-height: 19.6px;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  max-width: 1950px;
  width: 100%;
  // padding: 0 10px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   margin: 0;
//   color: #111111;
//   font-weight: 400;
//   font-family: 'Cairo', sans-serif;
// }

// h1 {
//   font-size: 70px;
// }

// h2 {
//   font-size: 36px;
// }

// h3 {
//   font-size: 30px;
// }

// h4 {
//   font-size: 24px;
// }

// h5 {
//   font-size: 18px;
// }

// h6 {
//   font-size: 16px;
// }

// p {
//   font-size: 16px;
//   font-family: 'Cairo', sans-serif;
//   color: #6f6f6f;
//   font-weight: 400;
//   line-height: 26px;
//   margin: 0 0 15px 0;
// }

// img {
//   max-width: 100%;
// }

// input:focus,
// select:focus,
// button:focus,
// textarea:focus {
//   outline: none;
// }

// a:hover,
// a:focus {
//   text-decoration: none;
//   outline: none;
//   color: var(--white-color);
// }

// ul,
// ol {
//   padding: 0;
//   margin: 0;
// }
